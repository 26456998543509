<template>
  <v-navigation-drawer permanent :mini-variant="false">
    <div class="profile-container d-flex py-4 align-center justify-center ">
      <template v-if="$isEmpty(user.photoURL)">
        <img :src="user.photoURL.cropped" alt="" class="profile-img mb-2 rounded elevation-6">
      </template>
      <template v-else>
        <v-avatar  size="60" color="white" class="profile-text white--text bg-loop ">
          <span class="text-shadow white--text">{{user.firstName.charAt(0)}}</span>
        </v-avatar>
      </template>
    </div>

    <!-- <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/women/81.jpg">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Jane Smith</v-list-item-title>
          <v-list-item-subtitle>Logged In</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template> -->

      <v-divider></v-divider>
      <search-lite @close-modal="closeModal"></search-lite>
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in menuFiltered"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
</template>

<script>
import {db} from '@/firebase';
const search = () => import("@/components/shared/search.vue");
const searchLite = () => import("@/components/shared/search-lite.vue");
const searchMobile = () => import("@/components/shared/search-lite-mobile.vue");
export default {
  name: 'navLogged',
  components:{searchLite},
  computed:{
    user: function(){return this.$store.state.user},
    menu: function(){return this.$store.state.mobile_menu},
    menuFiltered: function(){
      return this.menu.filter(item =>  item.text !== 'Search')
    }
  },
  data: () => ({
    photoURL: null,
    dialog: false,
  }),
  methods:{
    logout(){
      let vThis = this;
      this.$store.dispatch('logout').then(x=>{
        vThis.$router.push('/')
      });
    },
    closeModal(val){
      process.env.NODE_ENV === "development" ? console.log( 'close modal', val ) : null;
      if(val === true){this.dialog = false}
    },

    // setupUser(){
    //   let vThis = this;
    //   this.$store.dispatch('getCurrentUser').then(user => {
    //     vThis.user = user.data;
    //     vThis.userId = user.userId;
    //     // vThis.getMeetings(user.userId);
    //     // vThis.getProposals(user.userId)
    //   })
    // },

    //
    //
    //load meetingsLoaded
    getProposals(userId){
      let vThis = this;
      this.$store.dispatch('getProposals', {userId: userId, flag: 'active'});
    },
    getMeetings(userId){
      let vThis = this;
      this.$store.dispatch('getMeetings', userId);
    },
  },
  mounted(){
    // this.setupUser();



  }
};
</script>


<style lang="scss">
.v-navigation-drawer{
  width: 100%!important;
  background: transparent!important;
  .v-navigation-drawer__border{
    visibility: hidden;
  }
  .profile-container{
    .profile-text{
      width: 100%;
      font-size: 2rem;
    }
  }
}
.nav{
  .v-toolbar__content{
    // padding-right: 0;
    // padding-bottom: 0;
    padding:0!important;
  }
  .nav-links{
    a{
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
      margin-right: 16px;
      padding: 4px 8px;
      color: rgba(0,0,0,.5)!important;
      font-size: 1.1rem;
    }
  }
}
</style>
